<template>
	<div class="error">
		<h1 class="section_title">Error:</h1>
		<p class="error__message" v-html="errorMessage"></p>
	</div>
</template>

<script>
export default {
	name: 'error',
	computed: {
		errorMessage() {
			return this.$store.getters['misc/error']
		},
	},
}
</script>

<style scoped lang="scss">

</style>
